<template>
    <v-sheet class="elevation-3 pa-5">

        <coupon-form></coupon-form>
        <coupons-list></coupons-list>

    </v-sheet>

</template>

<script>
    import List from "@/components/coupons/List";
    import Form from "@/components/coupons/Form";

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
          'coupons-list': List,
          'coupon-form': Form
        }
    }
</script>

<style lang="scss" scoped>
</style>
