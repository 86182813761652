<template>
    <v-sheet class="elevation-3 pa-5">

      <codes-form></codes-form>
    <codes-list></codes-list>

    </v-sheet>

</template>

<script>

import List from "@/components/coupons/ecommerceCodes/List";
import Form from "@/components/coupons/ecommerceCodes/Form";

    export default {
      components: {
        'codes-list': List,
        'codes-form': Form
      },
      data () {
            return {
                search: '',
                openFilters: false,
            }
        },
    }
</script>

<style lang="scss" scoped>
</style>
