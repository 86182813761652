<template>
  <div>

    <v-expansion-panels class="my-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Filtra
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="3">
              <input-text label="Codice"
                          :value.sync="find.code"
                          :clearable="true"
                          @clickclear="getCodes"
              ></input-text>
            </v-col>
            <v-col>
              <v-checkbox
                  v-model="find.used"
                  label="Usati"
                  value="1"
                  hide-details
              ></v-checkbox>
              <v-checkbox
                  v-model="find.used"
                  label="Non usati"
                  value="0"
                  hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="filterByCode">cerca</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="codes"
        :hide-default-footer="true"
        class="elevation-0 clickable border-all"
        disable-pagination
    >

      <template v-slot:item.value="{ item }">
        <b>{{$n(item.value, 'currency')}}</b>
      </template>

      <template v-slot:item.used="{ item }">
        <b><check :val="item.used"></check> </b>
      </template>

    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-btn class="v-pagination__navigation"
             light
             small
             :disabled="!links.prev || isLoading"
             @click="getCodes(links.prev)"
      >
        <v-icon light>mdi-chevron-left</v-icon>
      </v-btn>
        <v-btn class="v-pagination__navigation"
               light
               small
               :disabled="!links.next || isLoading"
               @click="getCodes(links.next)"
        >
          <v-icon light>mdi-chevron-right</v-icon>
        </v-btn>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import Check from "@/components/commons/Check";
import InputText from "@/components/form/InputText";
import debounce from "debounce";

export default {
  name: "List",
  data() {
    return {
      find: {},
      filterMenu: false,
      filters: {
        exact: {},
        like: {}
      },
      headers: [
        {text: this.$t('coupons.ecommerce_code.fields.code'), value: 'code', sortable: false, width: '15%'},
        {text: this.$t('coupons.ecommerce_code.fields.value'), value: 'value', sortable: false, width: '10%'},
        {text: this.$t('coupons.ecommerce_code.fields.used'), value: 'used', sortable: false, width: '10%', align: 'center'},
        {text: this.$t('coupons.ecommerce_code.fields.coupon'), value: 'coupon', sortable: false},
        {text: this.$t('coupons.ecommerce_code.fields.user'), value: 'user', sortable: false, width: '10%'},
      ]
    }
  },

  components: {Check, InputText},

  mounted() {
    this.getCodes()
  },

  methods: {
    getCodes(url) {
      this.$store.dispatch('couponEcommerceCode/fetchCodes', {
        link: url,
        value: this.search,
        find: this.find
      })
    },
    filterByCode() {
      this.$store.dispatch('couponEcommerceCode/fetchCodes', {
        find: this.find
      })
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'couponEcommerceCode/loadingState',
      codes: 'couponEcommerceCode/getCodes',
      links: 'couponEcommerceCode/getLinks',
    }),

    loading: {
      get() {
        return this.isLoading
      },
      set(v) {
        this.$store.dispatch('couponEcommerceCode/setLoadingStatus', v)
      }
    },
  },

  watch: {
    "find.used": function (value) {
      if (!value) {
        delete(this.find['used'])
        this.getCodes()
      }
    }
  }

}
</script>

<style scoped>

</style>
