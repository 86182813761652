<template>
  <v-select
      v-if="ecommerceCouponValues"
      v-model="localModel"
      :label="$t('coupons.fields.value.ecommerce')"
      :items="ecommerceCouponValues"
      :rules="requiredRules"
      :required="required"
      outlined
      dense
  ></v-select>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "EcommerceCouponSelect",

  props: {
    value: String,
    required: {
      type: Boolean,
      default: false
    },
    requiredRules: Array
  },

  computed: {
    ...mapGetters({
      ecommerceCouponValues: 'coupon/getEcommerceValues'
    }),
    localModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      }
    }
  },

  mounted() {
    this.$store.dispatch('coupon/fetchEcommerceValues')
  }
}
</script>

<style scoped>

</style>