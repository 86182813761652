<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
        fullscreen
        id="coupon-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{ $t('commons.actions.new') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary white--text text-uppercase">
          {{ formTitle }}
        </v-card-title>
        <v-container>

          <v-form
              class="pb-16"
              v-if="showForm"
              ref="form"
              v-model="valid"
              lazy-validation
          >

            <v-row>
              <v-col
                  cols="6"
                  class="pt-0 pb-5"
              >
                <v-switch
                    class="mt-1"
                    v-model="coupon.replicable"
                    :label="$t('coupons.fields.replicable.label')"
                    inset
                    color="success"
                    :messages="$t('coupons.fields.replicable.help_text')"
                >
                </v-switch>
              </v-col>
              <v-col
                  cols="6"
                  class="pt-0 pb-5"
              >
                <v-row>
                  <v-col
                      cols="12"
                      class="pt-0 pb-5"
                  >
                    <testing-switch
                        :label="$t('commons.fields.testing.label')"
                        :value.sync="coupon.testing"
                        :message="$t('commons.fields.testing.help_text')"
                    ></testing-switch>
                  </v-col>
                </v-row>

                <divider></divider>

              </v-col>
            </v-row>

            <form-content-title :title="$t('coupons.form-section.general')"/>

            <v-row>
              <v-col cols="8" class="border-right">

                <v-row>
                  <v-col cols="4">
                    <p class="text--lighten-4">
                      Seleziona il livello minimo di compatibilità richiesto per la versione dell'app.
                    </p>
                    <app-version-select
                        :required-rules="requiredRules"
                        :value.sync="coupon.min_app_version"
                        :label="$t('commons.fields.min_app_version')"
                    ></app-version-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      cols="7"
                  >
                    <v-text-field
                        v-model="coupon.title"
                        :rules="requiredRules"
                        :label="$t('coupons.fields.title')"
                        required
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="5"
                  >
                    <v-text-field
                        v-model="coupon.sub_title"
                        :rules="requiredRules"
                        :label="$t('coupons.fields.sub_title')"
                        required
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-textarea
                        v-model="coupon.description"
                        :label="$t('coupons.fields.text')"
                        :rules="requiredRules"
                        outlined
                        dense
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      md="3"
                      sm="6"
                  >
                    <datepicker
                        :date.sync="coupon.online_at"
                        :required-rules="requiredRules"
                        :required="true"
                        :label="$t('coupons.fields.online_at')"
                    ></datepicker>
                  </v-col>

                  <v-col
                      md="3"
                      sm="6"
                  >
                    <datepicker
                        :date.sync="coupon.expires_at"
                        :required-rules="requiredRules"
                        :required="true"
                        :label="$t('coupons.fields.expires_at')"
                    ></datepicker>
                  </v-col>

                  <v-col
                      md="3"
                      sm="6"
                  >
                    <v-text-field
                        v-model="coupon.time_value"
                        :rules="requiredRules"
                        disabled
                        :label="$t('coupons.fields.time_value')"
                        required
                        type="number"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                      md="3"
                      sm="6"
                  >
                    <v-text-field
                        v-model="coupon.duration"
                        :rules="requiredRules"
                        :label="$t('coupons.fields.duration')"
                        required
                        type="number"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                      md="3"
                      sm="6"
                  >
                    <v-text-field
                        v-model="coupon.position"
                        :rules="requiredRules"
                        :label="$t('coupons.fields.position')"
                        required
                        type="number"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                </v-row>

                <v-row>

                  <v-col
                      md="4"
                      sm="12"
                  >
                    <countries
                        :country.sync="coupon.country"
                        :required-rules="requiredRules"
                    ></countries>
                  </v-col>

                  <v-col
                      md="4"
                      sm="12"
                  >
                    <languages
                        :language.sync="coupon.language"
                        :required-rules="requiredRules"
                    ></languages>
                  </v-col>

                  <v-col
                      md="4"
                      sm="12"
                  >
                    <color-picker
                        :color.sync="coupon.color"
                        :required-rules="requiredRules"
                    ></color-picker>
                  </v-col>
                </v-row>

                <v-row>

                  <v-col
                      md="3"
                      sm="6"
                  >
                    <coupon-threshold :disabled="!coupon.language"
                                      :threshold.sync="coupon.threshold"
                                      :language="coupon.language"
                                      :required-rules="requiredRules"
                                      :required="true"
                    >
                    </coupon-threshold>
                  </v-col>


                  <v-col
                      md="2"
                      sm="6"
                  >
                    <v-text-field
                        v-model="coupon.points"
                        :rules="requiredRules"
                        :label="$t('coupons.fields.points')"
                        required
                        type="number"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>


                </v-row>

                <v-row>

                  <v-col
                      md="4"
                      sm="12"
                  >
                    <coupon-type
                        :type.sync="coupon.type"
                        :required-rules="requiredRules"
                        :required="true"
                        :disabled="!coupon.country"
                        @change="resetValue"
                    ></coupon-type>
                  </v-col>

                  <v-col
                      md="4"
                      sm="12"
                      v-if="coupon.type === 'qrcode_discount' || coupon.type === 'qrcode_value' || coupon.type === 'ecommerce' || coupon.type === 'qrcode_product'"
                  >
                    <ecommerce-coupon-select
                        :value.sync="coupon.value"
                        :required-rules="requiredRules"
                        v-if="coupon.type === 'ecommerce'"
                    ></ecommerce-coupon-select>

                    <qr-code-coupon-select
                        :type="coupon.type"
                        :value.sync="coupon.value"
                        :required-rules="requiredRules"
                        v-if="coupon.type === 'qrcode_discount' || coupon.type === 'qrcode_value'"
                    ></qr-code-coupon-select>

                    <qr-code-coupon-products
                        v-if="coupon.type === 'qrcode_product'"
                        :product.sync="coupon.product"
                        :country="coupon.country"
                        :required-rules="requiredRules"
                    ></qr-code-coupon-products>
                  </v-col>

                  <v-col
                      md="4"
                      sm="12"
                      v-if="coupon.type === 'qrcode_discount' || coupon.type === 'qrcode_value' || coupon.type === 'qrcode_product'"
                  >
                    <v-switch
                        class="mt-1"
                        v-model="coupon.send_email"
                        :label="$t('coupons.fields.send_email')"
                        inset
                        color="success"
                    >
                    </v-switch>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      md="4"
                      sm="12"
                  >
                    <v-select
                        v-model="coupon.active"
                        :label="$t('coupons.fields.active')"
                        :items="statuses"
                        item-text="name"
                        item-value="value"
                        :rules="requiredRules"
                        outlined
                        dense
                    ></v-select>
                  </v-col>
                  <v-col
                      md="4"
                      sm="12"
                  >
                    <v-select
                        v-model="coupon.start_alert"
                        :label="$t('coupons.fields.start_alert')"
                        :items="startalertypes"
                        :rules="requiredRules"
                        outlined
                        :messages="['tipologia di alert allo start, dove può essere utilizzato?']"
                        dense
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <mission-parent
                        :mission.sync="coupon.mission"
                        :disabled="parentsDisabled"
                    ></mission-parent>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="4" class="text-center">
                <image-upload id="coupon_background"
                              :image.sync="coupon.bg_image"
                              :required-rules="requiredRules"
                              :required="true"
                              btn-label="background"
                ></image-upload>

              </v-col>
            </v-row>

            <v-divider></v-divider>
          </v-form>

          <json-debug :objs="[{coupon: coupon}]"/>

          <v-divider></v-divider>

          <v-footer fixed color="primary">
            <v-row id="footer-dialog">
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storeCoupon"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>
          </v-footer>

        </v-container>

        <overlay-loader
            :is-loading="isLoading"
            :absolute="true"
            :opacity="0.7"
        ></overlay-loader>


      </v-card>

    </v-dialog>

  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import moment from 'moment'
import clonedeep from 'lodash.clonedeep'
import CouponType from "@/components/form/CouponType";
import Datepicker from "@/components/form/Datepicker";
import DialogButtons from "@/components/form/DialogButtons";
import Countries from "@/components/form/Countries";
import Languages from "@/components/form/Languages";
import MissionParent from "@/components/form/MissionParent";
import ImageUpload from "@/components/form/ImageUpload";
import ColorPicker from "@/components/form/ColorPicker";
import FormContentTitle from "@/components/commons/FormContentTitle";
import EcommerceCouponSelect from "@/components/form/EcommerceCouponSelect";
import QrCodeCouponSelect from "@/components/form/QrCodeCouponSelect";
import CouponThreshold from "@/components/form/CouponThreshold";
import QrCodeCouponProducts from "@/components/form/QrCodeCouponProducts";
import AppVersionSelect from "@/components/form/AppVersionSelect";
import TestingSwitch from "@/components/form/TestingSwitch";
import {validationRules} from "@/mixins/validationRules";

import {EventBus} from "@/event-bus";


export default {
  name: "Form",

  mixins: [validationRules],

  components: {
    CouponType,
    MissionParent,
    Datepicker,
    DialogButtons,
    Countries,
    Languages,
    ImageUpload,
    ColorPicker,
    FormContentTitle,
    EcommerceCouponSelect,
    QrCodeCouponSelect,
    CouponThreshold,
    QrCodeCouponProducts,
    AppVersionSelect,
    TestingSwitch
  },

  data: () => ({
    isEditing: false,
    showForm: false,
    imgError: false,
    coupon: {},
    // ecommerceCouponValues: [],
    startalertypes:[
      'online', 
      'online-store', 
      'store',
    ],
    statuses: [
      {
        name: 'Attivo',
        value: 1
      },
      {
        name: 'Non attivo',
        value: 0
      }
    ],
    defaultCoupon: {
      title: '',
      sub_title: '',
      description: '',
      color: '#F1A14E',
      bg_image: {
        id: ''
      },
      threshold: '',
      value: '',
      country: '',
      language: '',
      hidden: 0,
      active: 1,
      time_value: 365,
      duration: 15,
      type: '',
      points: '',
      replicable: 1,
      online_at: moment().format('YYYY-MM-DD'),
      expires_at: moment().add(365,'days').format('YYYY-MM-DD'),
      position: 0,
      send_email: true,
      product: '',
      min_app_version: '',
      testing: true
    },
    dialog: false,
    valid: false,
    parentsDisabled: true
  }),

  computed: {
    ...mapGetters({
      isLoading: 'coupon/loadingState',
      missionParentCountry: 'mission/getParentMissionCountry',
    }),
    formTitle() {
      return (this.isEditing === true) ? this.$t('coupons.edit.title') : this.$t('coupons.create.title')
    },
    // expires_at() {
    //   return moment(this.coupon.online_at).add(this.coupon.time_value, 'days').format('DD-MM-YYYY');
    // }
  },

  mounted() {
    let self = this

    this.coupon = clonedeep(this.defaultCoupon)

    EventBus.$on('edit-coupon', function (coupon) {
      self.$store.dispatch('coupon/setCoupon', coupon.id)
          .then(() => {
            self.coupon = self.$store.getters['coupon/getCoupon']
          })
      self.isEditing = true
      self.dialog = true
    })

    this.showForm = true
  },

  methods: {
    async storeCoupon() {
      const valid = await this.$refs.form.validate()

      if (valid) {
        if (!this.isEditing) {
          await this.$store.dispatch('coupon/storeCoupon', this.coupon)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        } else {
          await this.$store.dispatch('coupon/updateCoupon', this.coupon)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
              })
              .catch(() => {
              })
        }
      }
    },

    resetValue() {
      this.coupon.value = ''
    },

    close() {
      this.dialog = false
      this.valid = true
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.isEditing = false
        this.coupon = clonedeep(this.defaultCoupon)
      })
    },
  },

  watch: {
    "coupon.type": {
      handler: function (type, oldType) {
        if ((type != oldType) && !this.isEditing) {
          this.coupon.value = ''
        }
      }
    },
    // "coupon.time_value":{
    //   handler: function(days){
    //     this.coupon.expires_at = moment(this.coupon.online_at).add(days, 'days').format('YYYY-MM-DD');
    //   }
    // },
    "coupon.expires_at":{
      handler: function(date){
        var start = moment(this.coupon.online_at);
        var end = moment(date)
        this.coupon.time_value = end.diff(start, 'days');
      }
    },
    "coupon.online_at":{
      handler: function(date){
        var start = moment(date);
        var end = moment(this.coupon.expires_at)
        this.coupon.time_value = end.diff(start, 'days');
      }
    },
    "coupon.country": {
      handler: function (country, oldCountry) {
        if (country && country !== oldCountry && country !== this.missionParentCountry) {
          this.$store.dispatch('mission/setParentMissions', country)
          this.parentsDisabled = false
          if (country === 'uk') {
            this.coupon.language = 'en'
          } else {
            this.coupon.language = country
          }
        }
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}
</style>
