<template>
  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          class="mb-2"
          v-bind="attrs"
          v-on="on"
          :disabled="isLoading"
      >
        {{ $t('coupons.ecommerce_code.actions.consume_code') }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ $t('coupons.ecommerce_code.actions.consume_code') }}
      </v-card-title>

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-container>

          <v-row v-if="Object.keys(code).length == 0">
            <v-col>
              <p>{{ $t('coupons.ecommerce_code.help') }}</p>
              <ecommerce-coupon-select
                  :value.sync="value"
                  :required-rules="requiredRules"
                  :required="true"
              ></ecommerce-coupon-select>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col class="text-center">
              <h2>{{code.code}}</h2>
              <h4>{{$n(code.value, 'currency')}}</h4>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row>
            <v-col>
              <dialog-buttons
                  :valid="valid"
                  :is-loading="isLoading"
                  :go-action-label="(Object.keys(code).length > 0) ? $t('coupons.ecommerce_code.actions.use_code') : $t('coupons.ecommerce_code.actions.find_code')"
                  @save="getOrBurnCode"
                  @close="close()"
              ></dialog-buttons>
            </v-col>
          </v-row>

        </v-container>
      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>

import clonedeep from 'lodash.clonedeep'
import DialogButtons from "@/components/form/DialogButtons";
import EcommerceCouponSelect from "@/components/form/EcommerceCouponSelect";
import {mapGetters} from "vuex";
import {validationRules} from "@/mixins/validationRules";

export default {
  name: "Form",

  components: {DialogButtons, EcommerceCouponSelect},

  mixins: [validationRules],

  data: () => ({
    value: "",
    dialog: false,
    sending: false,
    valid: false,
  }),

  mounted() {
    this.options = clonedeep(this.defaultOptions)
  },

  computed: {
    ...mapGetters({
      isLoading: 'couponEcommerceCode/loadingState',
      code: 'couponEcommerceCode/getCode',
    }),
  },

  methods: {
    async getOrBurnCode() {
      const valid = await this.$refs.form.validate()

      if (Object.keys(this.code).length > 0) {
        await this.$store.dispatch('couponEcommerceCode/burn', {id: this.code.id})
            .then(resp => {
              console.log('then close dialog resp ', resp)
              this.close()
            })
            .catch(err => {
              console.log('create error err ', err)
            })

      } else {

        if (valid) {
          await this.$store.dispatch('couponEcommerceCode/findByValue', {value: this.value})
              .then(resp => {
                console.log('then close dialog resp ', resp)
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        }
      }
    },

    close() {
      this.dialog = false
      this.valid = true
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.value = ''
        this.$store.commit('couponEcommerceCode/code', {})
      })
    },
  }
}
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>