<template>

    <v-select
        v-if="qrCodeCouponValues"
        v-model="localModel"
        :label="$t('coupons.fields.value.' + type)"
        :items="qrCodeCouponValues"
        :rules="requiredRules"
        :required="required"
        :loading="fetching"
        outlined
        dense
    ></v-select>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "QrCodeCouponSelect",

  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredRules: Array
  },

  computed: {
    ...mapGetters({
      qrCodeCouponTypes: 'coupon/getQrcodeTypes',
      qrCodeCouponValues: 'coupon/getQrcodeValues',
      fetching: 'coupon/fetchCouponTypeValuesStatus',
      loading: 'coupon/loadingState'
    }),
    localModel: {
      get() {
        return this.value
      },
      set(value) {
        console.log('emit update value ', value)
        this.$emit('update:value', value)
      }
    },
  },

  mounted() {
    this.fetchValues(this.type)
  },

  methods: {
    fetchValues(type) {
      this.$store.dispatch('coupon/fetchQrCodeValues', type)
    }
  },

  watch: {
    type: function (newType, oldType) {
      if (oldType !== newType || !newType) {
        this.fetchValues(newType)
      }
    }
  }
}
</script>

<style scoped>

</style>