<template>

  <v-select
      v-model="localModel"
      :label="$t('coupons.fields.qrcode_product')"
      :items="products"
      :disabled="disabled"
      :rules="requiredRules"
      :required="required"
      outlined
      dense
  ></v-select>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "QrCodeCouponProducts",

    props: {
      product: String,
      country: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      requiredRules: Array
    },

    computed: {
      ...mapGetters({
        products: 'coupon/getQrcodeProducts'
      }),

      localModel: {
        get() {
          return this.product
        },
        set(product) {
          this.$emit('update:product', product)
        }
      }
    },

    mounted() {
      if (this.products.length === 0) {
        this.$store.dispatch('coupon/setQrcodeProducts', this.country)
      }
    },

    watch: {
      country(newVal,oldVal) {
        if (newVal !== oldVal) {
          this.$store.dispatch('coupon/setQrcodeProducts', newVal)
        }
      }
    }
  }
</script>

<style scoped>

</style>