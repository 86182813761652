<template>
  <v-select
      v-model="localModel"
      :label="$t('coupons.fields.threshold')"
      :items="thresholds"
      :disabled="disabled"
      :rules="requiredRules"
      :required="required"
      item-text="label"
      item-value="id"
      outlined
      dense
  ></v-select>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "CouponThreshold",

    props: {
      threshold: {
        required: true
      },
      language: {
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      requiredRules: Array
    },

    computed: {
      ...mapGetters({
        thresholds: 'couponThreshold/getAllThresholds'
      }),

      localModel: {
        get() {
          return this.threshold
        },
        set(threshold) {
          this.$emit('update:threshold', threshold)
        }
      }
    },

    mounted() {
      // if (this.thresholds.length === 0) {
      //   this.$store.dispatch('couponThreshold/fetchAllThresholds', this.language)
      // }
    },

    watch: {
      language: {
        handler: function (language) {
          this.$store.dispatch('couponThreshold/fetchAllThresholds', language)
        },
        deep: true
      }
    }
  }
</script>

<style scoped>

</style>