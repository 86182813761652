<template>
  <v-text-field
      v-model="localModel"
      v-mask="mask"
      :label="$t('coupons.fields.color')"
      hide-details
      class="ma-0 pa-0"
      dense
      outlined
    >
    <template v-slot:append>
      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="localModel" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>

import {mask} from 'vue-the-mask'

  export default {
   name: "ColorPicker",

    directives: {mask},

    props: {
      color: String,
      required: {
        type: Boolean,
        default: false
      },
      requiredRules: Array
    },

    data: () => ({
      mask: '!#XXXXXXXX',
      menu: false,
    }),

    computed: {
      swatchStyle() {
        const {color, menu} = this
        return {
          backgroundColor: color,
          cursor: 'pointer',
          height: '25px',
          width: '25px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        }
      },

      localModel: {
        get() {
          return this.color
        },
        set(color) {
          this.$emit('update:color', color)
        }
      }
    }
  }
</script>

<style scoped>

</style>