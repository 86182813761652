<template>
  <div>

    <v-divider class="my-3"></v-divider>

<!--    <country-filter :loading="loading"-->
<!--                    :country.sync="filters.country"-->
<!--                    @change="applyFilter"-->
<!--    />-->
    <commons-filter :loading="isLoading"
                    :freeText.sync="filters.name"
                    :country.sync="filters.country"
                    :appVersion.sync="filters.min_app_version"
                    :testing.sync="filters.testing"
                    :free-search-filter="true"
                    :country-filter="true"
                    :app-version-filter="true"
                    :testing-filter="true"
                    @change="applyFilter"
    />


    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="coupons"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        disable-pagination
    >

      <template v-slot:item.title="{ item }">
        <b :class="{'blue--text' : item.testing}">{{ item.title }}</b>
        <template v-if="item.testing">
          <br><small class="text-decoration-underline font-weight-bold deep-orange--text text-uppercase font-italic">{{ $t('commons.only_for_beta_tester') }}</small>
        </template>
      </template>

      <template v-slot:item.expires_at="{ item }">
        {{ $parseDate(item.expires_at) }}
      </template>

      <template v-slot:item.infos="{ item }">
        <check :val="item.active"
               :label="$t('coupons.fields.active')"
        />

        <check :val="item.testing"
               :label="$t('commons.only_beta_tester')"
        />

        <check :val="item.replicable"
               :label="$t('coupons.fields.replicable.label')"
        />

        <check :val="item.hidden"
               :label="$t('coupons.fields.hidden')"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editCoupon(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
            v-if="!isLoading"
            small
            class="mr-2"
            @click="cloneCoupon(item.id)"
        >
          mdi-content-copy
        </v-icon>
      </template>
      
      <template v-slot:item.active="{ item }">
        <v-icon color="success" v-if="(item.active)">mdi-radioactive</v-icon>
        <v-icon color="error" v-if="!(item.active)">mdi-radioactive-off</v-icon>
      </template>


    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getCoupons()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

    <json-debug :objs="[{coupons: coupons}]"></json-debug>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";
import Check from "@/components/commons/Check";
import CountryFilter from "@/components/filters/CountryFilter";
import CommonsFilter from "@/components/filters/CommonsFilter";

export default {
  name: "List",

  data() {
    return {
      couponFilter: '',
      filterMenu: false,
      filters: {},
      headers: [
        {text: '#', value: 'id', sortable: false, width: '5%'},
        {text: this.$t('missions.fields.active.label'), value: 'active', sortable: false, align: "center"},
        {text: this.$t('coupons.fields.title'), value: 'title', sortable: false},
        {text: this.$t('coupons.fields.min_app_version'), value: 'min_app_version', sortable: false, width: '10%', align: "center"},
        {text: this.$t('coupons.fields.type'), value: 'type', sortable: false, width: '10%'},
        {text: this.$t('coupons.fields.points'), value: 'points', sortable: false, width: '5%'},
        {text: this.$t('coupons.fields.threshold'), value: 'threshold_level', sortable: false, width: '10%', align: 'center'},
        {text: this.$t('coupons.fields.country'), value: 'country', sortable: false, width: '10%', align: 'center'},
        {text: this.$t('coupons.fields.generic_info'), value: 'infos', sortable: false, width: '15%'},
        {text: this.$t('coupons.fields.expires_at'), value: 'expires_at', sortable: false, width: '15%'},
        {text: '', value: 'actions', sortable: false, width: '8%', align: 'center'},
      ]
    }
  },

  components: {
    Check,
    CommonsFilter
  },

  mounted() {
    this.reset()
    this.getCoupons()
  },

  methods: {
    reset() {
      this.$store.dispatch('coupon/reset', this.filters)
    },

    getCoupons() {
      this.$store.dispatch('coupon/fetchCoupons')
    },

    async cloneCoupon (coupon) {
      console.log('clone mission ' , coupon)
      await this.$confirm(this.$t('coupons.replicate.confirm')).then(res => {
        console.log('res ' , res)

        this.$apiClient.get('coupons/' + coupon + '/clone')
            .then(response => {
              this.isEditing = true
              EventBus.$emit('edit-coupon', response.data)
              this.dialog = true
            })

      })
    },

    details(v) {
      this.$router.push({
        name: 'coupon-show',
        params: {
          id: v.id,
          name: v.name
        }
      })
    },

    editCoupon (coupon) {
      console.log('edit coupon ' , coupon)
      this.isEditing = true
      EventBus.$emit('edit-coupon', coupon)
      this.dialog = true
    },

    couponDetails(coupon) {
      this.$router.push({
        name: 'coupon-details',
        params: {
          id: coupon.id,
          type: coupon.type
        }
      })

    },

    applyFilter() {
      this.$store.dispatch('coupon/setFilters', this.filters)
      this.getCoupons()
    },

    removeCouponFilter(event) {
      delete this.filters.exact['id']
      this.couponFilter = ''
      this.getCoupons()
      event.stopPropagation()
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'coupon/fetchingStatus',
      coupons: 'coupon/getCoupons',
      pagination: 'coupon/getPagination'
    }),

    loading: {
      get() {
        return this.isLoading
      },
      set(v) {
        this.$store.dispatch('tenant/setLoadingStatus', v)
      }
    },
  }
}
</script>

<style scoped>

</style>
